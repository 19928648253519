<template>
    <div class="noData">
        <div class="top">
            <img src="../../assets/img/noContent.png" alt="">
        </div>
        <div class="noList">暂无数据!!</div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
    .noData{
        width: 1200px;
        height: 600px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top{
            width: 180px;
            height: 180px;
            img{
                width: 180px;
                height: 180px;
            }
        }
        .noList{
            margin-top: 15px;
            font-size: 24px;
            font-weight: 400;
            line-height: 16px;
            color: #333333;
        }
    }
</style>