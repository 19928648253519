import { render, staticRenderFns } from "./courseList.vue?vue&type=template&id=5434e76e&scoped=true&"
import script from "./courseList.vue?vue&type=script&lang=js&"
export * from "./courseList.vue?vue&type=script&lang=js&"
import style0 from "./courseList.vue?vue&type=style&index=0&id=5434e76e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5434e76e",
  null
  
)

export default component.exports