<template>
    <div class="box">
        <div class="titleList">
            <div :class="currentIndex === index?'titleActive':'tit'" v-for="(item,index) in titleList" :key="index" @click="maxTitleClick(index)">{{item.name}}</div>
        </div>
        <div class="titleBtn">
            <div class="classification">
                <div :class="currentItemIndex === index ? 'itemActive' :'item'" v-for="(item,index) in childList" :key="index" @click="smallTitleClick(index)">{{item}}</div>
            </div> 
            <div class="seach">
                <input class="input" type="text" placeholder="搜索课程" v-model="inputText">
                <input class="btn" type="button" value="搜索" @click="seach">
            </div>
        </div>
    </div>
</template>        

<script>
export default {
    props:{
        titleList:{
            type:Array,
            default:()=>[]
        },
        childList:{
            type:Array,
            default:()=>[]
        },
        currentIndex:{
            type:Number,
            default:0
        },
        currentItemIndex:{
            type:Number,
            default:0
        },
    },
    data(){
        return{
            inputText:''      
        }
    },
    methods:{
        //搜索
        seach(){
            this.$emit('inputSeach',this.inputText)
        },
        maxTitleClick(index){
            this.$emit('topTitle',index)
        },
        smallTitleClick(index){
            this.$emit('botTitle',index)
        }
    }
}
</script>

<style scoped lang="less">
.box{
    width: 1200px;
    height: 108px;
    background: #fff;
    padding: 18px 10px 18px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .titleList{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        .tit{
            margin-right: 30px;
            color: #333333;
            cursor: pointer;
        }
        .titleActive{
            padding: 3px 5px;
            background: #007131;
            margin-right: 30px;
            border-radius: 2px;
            color: #fff;
            cursor: pointer;
        }
    }
    .titleBtn{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .classification{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            .item{
                padding: 3px 9px;
                color: #333333;
                background: #fff;
                margin-right: 10px;
                cursor: pointer;
            }
            .itemActive{
                padding: 3px 9px;
                color: #fff;
                background: #007131;
                margin-right: 10px;
                border-radius: 2px;
                cursor: pointer;
            }
        }
        .seach{
            height: 24px;
            width: 193px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .input{
                width: 135px;
                height: 24px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                border-radius: 2px;
                outline: none;
                padding-left: 10px;
            }
            .btn{
                width: 47px;
                height: 24px;
                background: #007131;
                border-radius: 2px;
                outline: none;
                border: none;
                color: #fff;
                cursor: pointer;
            }
        }
    } 
}
</style>