<template>
<div>
  <common-header :current-index="1"></common-header>
  <div class="contationBox">
      <div class="contationItem">
        <courseHeader 
        :titleList="titleList" 
        :currentIndex="currentIndex" 
        :currentItemIndex="currentItemIndex" 
        :childList="childList"
        @topTitle="maxClick"
        @botTitle="smallClick"
        @inputSeach="toSeachList"
        >
        </courseHeader>
        <courseMaxList v-if="list.length>0" @playDetail="toPlay" :list="list"></courseMaxList>
        <noDate v-else></noDate>
        <paging ref="pagChild" :total="total" :rows="rows" @clickPage="pageList"></paging>
      </div>
  </div>
  <common-footer></common-footer>
</div>
</template>
<script>
import { categoryList,curriculumList } from '@/common/public.js'
import commonHeader from '@/components/commonHeader'
import courseHeader from '@/components/course/courseHeader'
import courseMaxList from '@/components/course/courseMaxList'
import noDate from '@/components/course/noDate'
import paging from '@/components/course/paging'
import commonFooter from '@/components/commonFooter'
export default {
  components: {
    commonHeader,courseHeader,courseMaxList,noDate,paging,commonFooter
  },
  data(){
    return{
      titleList:[],
      currentIndex:0,
      currentItemIndex:0,
      flagIndex:false,
      id:'',//首页传递过来的id分类
      page:1,
      rows:25,
      total:0,//总页数
      seachTitle:'',//搜索标题
      childList:['全部','免费课','付费课'],
      isFree:'',//1收费  0免费
      list:[],//课程总列表
    }
  },
  created(){
      
      this.id= this.$route.query.id;
      this.flagIndex= this.$route.query.flag;
  },
  methods:{
    //获取分类列表
    getList(){
      categoryList().then(res=>{
        res.result.mapList.unshift({
          id: '',
          name: "全部"
        })
        this.titleList = res.result.mapList;
        if(this.flagIndex){
          this.currentIndex = this.titleList.findIndex((value)=>{
            return value.id == this.id
          })
        }
        this.getCurriculumList()
      })
    },
    //获取课程列表
    getCurriculumList(){
      let categoryId = this.titleList[this.currentIndex].id;
      let page = this.page;
      let row = this.rows;
      let name = this.seachTitle;
      let isFree = this.isFree;
      let data = {
        categoryId,page,row,name,isFree
      };
      curriculumList(data).then(res=>{
        this.total = res.result.listSize;
        this.rows = res.result.rows;
        this.list = res.result.mapList;
      })
    },
    //点击切换下一页、
    pageList(index){
      this.page = index;
      this.getCurriculumList()
    },
    //点击切换index
    maxClick(index){
      this.currentIndex = index;
      this.page = 1;
      this.$refs.pagChild.goBackPage()
      this.getCurriculumList()
    },
    //搜索事件
    toSeachList(text){
      this.seachTitle = text;
      this.page =  1;
      this.$refs.pagChild.goBackPage()
      this.getCurriculumList()
    },
    //点击切换收费免费
    smallClick(index){
      this.currentItemIndex = index;
      this.page =  1;
      this.$refs.pagChild.goBackPage()
      if(index == 0){
        this.isFree = ''
        this.getCurriculumList()
      }else if(index == 1){
        this.isFree = 1;
        this.getCurriculumList()
      }else{
        this.isFree = 0;
        this.getCurriculumList()
      }
    },
    //跳转到播放详情 path:'/playdetails'
    toPlay(id){
      window.localStorage.setItem('videoId',id)
      this.$router.push({
        path:'/playdetails',
        query:{id,name:this.titleList[this.currentIndex].name,ids:this.titleList[this.currentIndex].id}
      })
    }
  },
  mounted(){
    this.getList()
  }
}
</script>

<style scoped lang="less">
.contationBox{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #F1F1F1;
    .contationItem{
      width: 1200px;
      padding: 30px 0 60px 0;
    }
}
</style>
