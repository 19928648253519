<template>
  <div class="common-header">
    <div class="tab">
      <div class="login">
        <img @click="toIndex" src="../assets/img/logo.png" alt="" />
      </div>
      <div class="listBox">
          <div class="list">
            <div
              :class="currentIndex == index ? 'active' : ''"
              v-for="(item, index) in tabs"
              :key="index"
              @click="clickItem(index)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="loging">
            <div class="noLogin" v-if="!$store.state.token" @click="toLogin">{{login.name}}</div>
            <div class="success" v-else>
              <div class="userLogin" @click="shouList">
                <img class="img" :src="$store.state.user.headPic" alt=""><span class="span">{{$store.state.user.nickname}}</span>
              </div>
              <div class="itemFlex" v-if="setList">
                <div :class="subIndex === index ? 'subActive':'item'" @click="toSub(index)" v-for="(item,index) in submen" :key="index">{{item.name}}</div>
              </div>
            </div>
          </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import { exitApp } from '@/common/public.js'
export default {
  props:{
    currentIndex:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      tabs: [{
        name: "首页",
        path: "/"
      }, {
        name: "教学视频",
        path: "/courseList"
      }, {
        name: "关于我们",
        path: "/about"
      }, {
        name: "付费资讯",
        path: "/paymentInfor"
      }],
      login:{
        name: "登陆/注册",
        path: "/login"
      },
      submen:[
        {name:'浏览记录',path:'/setting'},
        {name:'我的订单',path:'/setting'},
        {name:'设置',path:'/setting'},
        {name:'退出登录'}
        ],
        setList:false,
        subIndex:0
    };
  },
  methods: {
    //跳转到登录页面
    toLogin(){
      this.$router.push({
        path:this.login.path
      })
    },
    //点击跳转到相应页面
    clickItem(index) {
      this.$router.push({path: this.tabs[index].path})
    },
    shouList(){
      this.setList = !this.setList;
    },
    //跳转到用户的信息页面列表
    toSub(index){
      if(index == 3){
        exitApp().then(()=>{
            //调用vuex封装方法
            this.$store.commit('logout')
            this.$router.push({
              path:'/'
            })
            this.$message({
                showClose: true,
                message: '退出登录',
                type: 'success'
            });
        })
      }else{
        if(this.$router.path == '/setting') return
        this.$router.push({
          path:this.submen[index].path,
          query:{index:index}
        })
        this.setList = false;
        this.subIndex = 0;
      }
    },
    //跳转到首页
    toIndex(){
      this.$router.push({
        path:'/'
      })
    }
  },
  mounted () {
    
  }
};
</script>
<style scoped lang="less">
.common-header {
  width: 100%;
  height: 72px;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .tab {
    width: 1200px;
    height: 72px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .login {
      width: 72px;
      height: 46px;
      cursor: pointer;
      img {
        width: 72px;
        height: 46px;
      }
    }
    .listBox{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .loging{
          margin-left: 30px;
          font-size: 16px;
          font-weight: 400;
          line-height: 72px;
          color: #333333;
          cursor: pointer;
          .success{
            position: relative;
            .userLogin{
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-content: center;
              .img{
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }
              .span{
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #333333;
                margin-left: 15px;
              }
            }
            .itemFlex{
              position: absolute;
              bottom: -120px;
              left: -15px;
              width: 90px;
              padding: 0 12px;
              height: 115px;
              background: #FFFFFF;
              box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              z-index: 9999;
              .item{
                border-bottom: 1px solid #eee;
                color: #333;
                padding: 5px 0;
              }
              .subActive{
                border-bottom: 1px solid #eee;
                color: #097638;
                padding: 5px 0;
              }
            }
          }
      }
      .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        div {
          margin-left: 30px;
          font-size: 16px;
          font-weight: 400;
          line-height: 72px;
          color: #333333;
          cursor: pointer;
        }
        .active {
          color: #097638;
          position: relative;
          &:after{
            content: '';
            width: 80%;
            height: 4px;
            display: block;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            background-color: #fed23e;
          }
        }
      }
    }
  }
}
</style>