<template>
    <div class="boxCou">
        <div class="item" v-for="(item,index) in list" :key="index" @click="toDetails(item.id)">
            <div class="img">
                <img :src="item.coverUrl" alt="">
            </div>
            <div class="context">
                <div class="title">{{item.name}}</div>
                <div class="time">{{item.publishTime | toTime(item.publishTime)}}</div>
                <div class="viplist">
                    <span class="vip" v-if="item.isFree == 0">VIP</span>
                    <span class="admission" v-else>免费</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        list:{
            type:Array,
            default:()=>[]
        }
    },
    methods:{
        //跳转播放页面
        toDetails(id){
            this.$emit('playDetail',id)
        }
    }
}
</script>

<style scoped lang="less">
.boxCou{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px 0 0 0;
    .item:nth-child(5n+5) {
        margin-right: 0;
    }
    .item{
        width: 224px;
        margin-bottom: 20px;
        margin-right: 20px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .img{
            width: 224px;
            height: 126px;
            background: #E1E1E1;
            overflow: hidden;
            img{
                width: 224px;
                height: 126px;
                border-radius: 4px 4px 0px 0px;
                transition: all .5s;
            }
            // img:hover{
            //     transform: scale(1.4);
            // }
        }
        .context{
            width: 100%;
            padding:0 10px 13px 10px;
            display: flex;
            flex-direction: column;
            .title{
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                margin-top: 6px;
                color: #333333;
            }
            .time{
                font-size: 12px;
                font-weight: 400;
                margin-top: 6px;
                color: #999999;
            }
            .viplist{
                font-size: 18px;
                font-weight: 400;
                margin-top: 8px;
                .vip{
                    color: #FF1D00;
                }
                .admission{
                    color: #097638;
                }
            }
        }
    }
    .item:hover{
        img{
            transform: scale(1.4);
        }
        .title{
            color: #097638;
        }
    }
}
</style>