<template>
    <div class="box">
        <div class="block">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size = "rows"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        total:{
            type:Number,
            default:0
        },
        rows:{
            type:Number,
            default:25
        }
    },
    data(){
        return{
            currentPage: 1,
        }
    },
     methods: {
      //回到第一页
      goBackPage(){
          this.currentPage = 1;
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
        this.$emit('clickPage',val)
      }
    }
}
</script>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color:#097638;
    color: #FFF;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    margin: 0 5px;
    padding: 0 3px;
    background-color: #fff;
    color: #999;
    min-width: 30px;
    border-radius: 1px;
    border: 1px solid #D2D0D0;
}
</style>
<style scoped lang="less">
.box{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .block{
        width: 650px;
        height: 25px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
</style>