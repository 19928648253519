<template>
    <div class="box">
        <div class="contation">
            <div class="top">
                <div class="left">
                    <div class="icon">
                        <img src="@/assets/img/logo.png" alt="">
                    </div>
                    <div class="phone">
                        <div class="about">关于我们</div>
                        <div class="num">联系方式： 0475-8227575/ 0475-8233924</div>
                        <div class="address">联系地址： 内蒙古通辽市科尔沁区永安路1310号</div>
                    </div>
                </div>
                <div class="right">
                    <div class="ewmOne">
                        <img src="@/assets/img/appIcon.png" alt="">
                        <span>安卓下载</span>
                    </div>
                    <div class="ewmOne">
                        <img src="@/assets/img/appIcon.png" alt="">
                        <span>ios下载</span>
                    </div>
                    <div class="ewmTwo">
                        <img src="@/assets/img/weixinIcon.png" alt="">
                        <span>官方微信</span>
                    </div>
                </div>
            </div>
            <div class="bot">
                Copyright © 2019 通辽市英国英语语言学校.蒙ICP备19002218号 
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    height: 320px;
    background:rgb(111,172,156);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .contation{
        width: 1200px;
        height: 320px;
        background: rgb(111, 172, 156);
        padding: 83px 0 37px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .top{
            width: 100%;
            height: 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center; 
            .left{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .icon{
                    width: 182px;
                    height: 115px;
                    // margin-right: 50px;
                    img{
                        width: 182px;
                        height: 115px;
                    }
                }
                .phone{
                    height: 115px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .about{
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                    .num{
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                    .address{
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                }
            }
            .right{
                width: 420px;
                height: 170px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .ewmOne{
                    width: 130px;
                    height: 170px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 130px;
                        height: 133px;
                    }
                    span{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                }
                .ewmTwo{
                    width: 130px;
                    height: 170px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    img{
                        width: 130px;
                        height: 133px;
                    }
                    span{
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                    }
                }
            }
        }
        .bot{
            width: 1200px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #FFFFFF;
        }
    }
}
</style>